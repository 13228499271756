<template>
  <div>
    <van-nav-bar :title="title" right-text="多发" @click-right="onClickRight" />

    <div class="tags">
      <van-tabs @click="changeTab" color="#1989fa" :swipeable="true">
        <van-tab
          :title="item.text"
          :name="item.value"
          v-for="(item, index) in tagsList"
          :key="index"
        ></van-tab>
      </van-tabs>
    </div>
    <!-- v-model="loading" -->
    <div class="loading">
      <van-loading color="#1989fa" v-if="loading" :vertical="true"
        >加载中...</van-loading
      >
    </div>
    <van-search
      v-model="keywords"
      show-action
      placeholder="请输入搜索关键词"
      @search="onSearch(keywords)"
      v-if="isSearch"
    >
      <template #action>
        <div @click="onSearch(keywords)">搜索</div>
      </template>
    </van-search>

    <van-list :finished="finished" @load="onLoad">
      <van-checkbox-group v-model="checked">
        <van-cell-group inset>
          <van-cell
            v-for="(item, index) in list"
            :key="index"
            :title="item.text"
            @click="toggle(item)"
            clickable
          >
            <template #right-icon>
              <van-checkbox
                shape="square"
                :name="item.text"
                :ref="(el) => (checkboxRefs[index] = el)"
                @click.stop
              />
            </template>
          </van-cell>
        </van-cell-group>
      </van-checkbox-group>
    </van-list>
    <div class="footer">
      <van-button
        type="info"
        size="normal "
        style="float: left; width: 50%"
        :square="true"
        @click="sendWechatMessage()"
        >发送企业微信</van-button
      >
      <van-button
        type="info"
        size="normal "
        style="width: 50%"
        :square="true"
        @click="sendCommonMessage()"
        >发送普通短信</van-button
      >
    </div>
    <!--编辑信息历史内容弹窗-->
    <van-dialog
      title="发送信息"
      v-model="isShow"
      cancelButtonText="发送企业微信"
      confirmButtonText="发送普通短信"
      show-cancel-button
      :beforeClose="beforeClose"
      :closeOnClickOverlay="true"
      :overlay="true"
      style="overflow: auto; height: 85%; width: 90%"
    >
      <div style="height: 38.5em">
        <van-field
          v-model="oldContent"
          placeholder=""
          type="textarea"
          :autosize="{ maxHeight: 1000, minHeight: 50 }"
        />
        <div style="margin-top: 30em">
          <van-button
            type="default"
            size="normal "
            style="float: left; width: 30%"
            :square="true"
            >操作视频</van-button
          >
          <van-button
            type="info"
            size="normal "
            style="width: 40%"
            :square="true"
            >下载地址</van-button
          >
          <van-button
            type="default"
            size="normal "
            style="width: 30%"
            :square="true"
            >认证地址</van-button
          >
        </div>
      </div>

      <!-- <van-field
        v-model="dname"
        v-if="hasDname"
        label="可更改内容1"
        type="textarea"
        placeholder="可更改内容1"
      />
      <van-field
        v-model="name"
        v-if="hasName"
        label="可更改内容2"
        type="textarea"
        placeholder="可更改内容2"
      /> -->
    </van-dialog>
    <!--历史记录弹窗-->

    <van-dialog
      title="历史发送记录"
      v-model="showHistory"
      cancelButtonText="取消"
      confirmButtonText="确定"
      show-cancel-button
      :beforeClose="hisClose"
      :closeOnClickOverlay="true"
      :overlay="true"
      style="overflow: auto; height: 85%; width: 90%"
    >
      <div style="height: 38.5em">
        <van-swipe-cell v-for="(item, index) in historyList" :key="index">
          <van-cell
            :border="false"
            :title="item.data"
            :value="item.sendType"
            :label="item.createTime"
          />
          <template slot="right" style="footer">
            <van-button
              square
              type="danger"
              text="发送企业微信"
              @click="sendWechatMessage(item)"
            />
            <van-button
              square
              type="primary"
              text="发送普通短信"
              @click="sendCommonMessage(item)"
            />
          </template>
        </van-swipe-cell>
      </div>
    </van-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      keywords: "",
      tagsList: [
        {
          text: "订卷",
          value: 1,
        },
        {
          text: "返利",
          value: 2,
        },
        {
          text: "会员",
          value: 3,
        },
        {
          text: "自定义",
          value: 4,
        },
        {
          text: "历史",
          value: 5,
        },
        {
          text: "搜索",
          value: 6,
        },
      ],
      list: [],
      loading: true,
      finished: false,
      checkboxRefs: [],
      checked: [],
      appId: "",
      agentid: "", //自建应用id
      timestamp: "", //时间戳
      nonceStr: "", //随机串
      signature: "", //基础签名
      agentSignature: "", //自建应用签名
      userInfo: "", //微信用户的userid
      content: "", //发送信息内容
      userUnionId: "", //微信用户唯一标识
      isShow: false, //弹窗
      showHistory: false, //历史记录弹窗
      oldContent: "", //原填充消息
      dname: "", //填充变量一
      name: "", //填充变量二
      resultContent: "", //短信发送结果
      metadata: "", //模板
      newContent: "", //更改之后的消息
      hasName: false, //是否有该字段
      hasDname: false,
      contentSignature: "", //短信唯一标识
      type: 1, //信息类型
      historyList: [], //
      showTabs: true, //是否展示tab
      isSearch: false, //是否点击搜索
      title: "", //头部标题
    };
  },
  methods: {
    //tab页点击触发
    changeTab(event) {
      if (event === 6) {
        this.isSearch = true;
        this.showTabs = false;
        this.list = [];
      } else if (event === 5) {
        this.isSearch = false;
        this.list = [];
        this.searchHis();
      } else {
        this.isSearch = false;
        this.handleTagClick({ value: event });
      }
    },
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      var item = {};
      item.value = 1;
      this.handleTagClick(item);
    },
    //点击复选框触发
    toggle(index) {
      if (index.name) {
        this.hasName = true;
        this.name = index.name;
      }
      if (index.dname) {
        this.dname = index.dname;
        this.hasDname = true;
      }
      this.oldContent = index.text;
      this.contentSignature = index.contentSignature;
      this.metadata = index.metadata;
    },
    //根据类型查询消息内容
    handleTagClick(item) {
      this.list = [];
      this.$axios
        .post(
          `${this.$base}/admin/weChat`,
          {
            unionid: this.userUnionId,
            type: item.value,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (res.code === 200 && res.data) {
            res.data.forEach((item, index) => {
              this.list.push({
                text: item.data,
                value: index,
                name: item.name,
                dname: item.dname,
                metadata: item.metadata,
                contentSignature: item.signature,
                sendType: item.sendType,
                createTime: item.createTime,
              });
            });

            this.type = item.value;
          } else if (!res.data) {
            this.$toast("暂无更多数据");
          }
          // 加载状态结束
          this.loading = false;
        });
      this.finished = true;
    },
    onSearch(keyword) {
      var result = [];
      if (keyword) {
        this.list.map(function (item) {
          if (item.text.indexOf(keyword) > -1) {
            result.push(item);
          }
        });
        this.list = result;
      }
      this.$toast(keyword);
    },
    //初始化微信ticket
    initWxTicket() {
      this.$axios
        .get(
          `${this.$base}/fission/page/getTicket?agentId=1000029&type=3&url=${
            location.href.split("#")[0]
          }`
        )
        .then((res) => {
          if (res.code === "200") {
            this.appId = res.data.pId;
            this.agentid = res.data.agentId;
            this.timestamp = res.data.timeStamp;
            this.nonceStr = res.data.nonceStr;
            this.signature = res.data.signature;
            this.agentSignature = res.data.agentSignature;
            this.config();
          }
        });
    },
    config() {
      window.wx.config({
        beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: this.appId, // 必填，企业微信的corpID
        agentid: this.agentid, //第三方应用id
        timestamp: this.timestamp, // 必填，生成签名的时间戳
        nonceStr: this.nonceStr, // 必填，生成签名的随机串
        signature: this.signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
        jsApiList: ["agentConfig", "getCurExternalContact"], // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
      });

      window.wx.ready(function () {
        // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
        window.wx.checkJsApi({
          jsApiList: ["agentConfig"], //需要检测的js接口
          success: function () {},
          fail: function () {
            console.log("--------接口校验失败---------");
          },
        });
      });

      window.wx.error(function (res) {
        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
        console.log("-----sdk校验失败:" + res.errMsg);
      });

      /**
       * 获取外部联系人id
       */
      window.wx.agentConfig({
        corpid: this.appId, // 必填，企业微信的corpID
        agentid: this.agentid,
        timestamp: this.timestamp, // 必填，生成签名的时间戳
        nonceStr: this.nonceStr, // 必填，生成签名的随机串
        signature: this.agentSignature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
        jsApiList: ["getCurExternalContact", "getContext", "sendChatMessage"], // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
        success: this.onSuccess,
        fail: function () {
          // if (res.err_msg.indexOf("function not exist") > -1) {
          //   this.jsApiList.performAlert("版本过低请升级");
          // }
        },
      });
    },
    onSuccess() {
      window.wx.invoke("getContext", {}, (res) => {
        if (res.err_msg == "getContext:ok") {
          // console.log("-------进入页面的类型:" + res); //返回进入H5页面的入口类型，目前有normal、contact_profile、single_chat_tools、group_chat_tools、chat_attachment
          //可用于调用getShareInfo接口
          window.wx.invoke("getCurExternalContact", {}, (res) => {
            if (res.err_msg == "getCurExternalContact:ok") {
              this.userInfo = res.userId;
              this.initUser();
            } else {
              //错误处理
              console.log("-----------无法获取外部用户:" + res.err_msg);
            }
          });
        } else {
          //错误处理
        }
      });
    },
    initUser() {
      this.$axios
        .get(`${this.$base}/fission/page/getByUserId?userId=${this.userInfo}`)
        .then((res) => {
          if (res.errcode === 0) {
            this.userUnionId = res.external_contact.unionid;
            this.title = res.external_contact.name;
            this.onLoad();
          }
        });
    },
    //发送企业微信消息
    sendWechatMessage(item) {
      console.log(item);
      //判断是否用最新的编辑结果
      if (this.newContent) {
        this.content = this.newContent;
      } else if (item) {
        this.content = item.data;
      } else {
        this.checked.forEach((item, index, array) => {
          console.log(array);
          this.content += item + "\n\n";
        });
      }

      window.wx.invoke(
        "sendChatMessage",
        {
          msgtype: "text", //消息类型，必填
          text: {
            content: this.content, //文本内容
          },
        },
        (res) => {
          console.log("服务指引返回结果", res);
          if (res.err_msg == "sendChatMessage:ok") {
            //记录日志
            this.$axios
              .post(
                `${this.$base}/admin/weChat/save`,
                {
                  data: this.content,
                  dname: this.dname,
                  metadata: this.metadata,
                  name: this.name,
                  signature: this.contentSignature,
                  sendType: "企业微信发送", //企业微信发送
                  type: this.type,
                  unionid: this.userUnionId,
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              )
              .then(() => {
                //发送成功
                this.content = "";
                this.handleTagClick({ value: this.type });
              });
          }
        }
      );
    },
    //发送普通消息
    sendCommonMessage(item) {
      console.log(item);
      if (item) {
        this.metadata = item.metadata;
        if (item.dname) {
          this.dname = item.dname;
          this.hasDname = true;
        }
        if (item.name) {
          this.name = item.name;
          this.hasName = true;
        }

        this.contentSignature = item.signature;
        this.type = item.type;
        this.sendType = item.sendType;
        this.oldContent = item.data;
      }
      this.show = true;
      this.isShow = !this.isShow;
    },
    //弹窗点击事件触发
    beforeClose(action, done) {
      //普通短信只可发一条
      if (this.list.length > 1) {
        this.$toast("普通短信只可编辑一条！");
        done();
      }
      this.newContent = this.metadata;
      if (this.name) {
        //替换模板中的字符
        this.newContent = this.newContent.replace(/{{name}}/g, this.name);
      }
      if (this.dname) {
        this.newContent = this.newContent.replace(/{{dname}}/g, this.dname);
      }
      //发送普通短信 /admin/weChat/sendMsg/log
      if (action === "confirm") {
        this.resultContent = this.$dialog
          .confirm({
            title: "编辑结果",
            message: this.newContent,
          })
          //短信发送
          .then(() => {
            this.$axios
              .post(
                `${this.$base}/admin/weChat/sendMsg`,
                {
                  unionid: this.userUnionId,
                  type: this.type,
                  name: this.dname,
                  dname: this.dname,
                  signature: this.contentSignature,
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              )
              .then((res) => {
                if (res.code === 200) {
                  //记录日志
                  this.$axios
                    .post(
                      `${this.$base}/admin/weChat/save`,
                      {
                        data: this.newContent,
                        dname: this.dname,
                        metadata: this.metadata,
                        name: this.name,
                        signature: this.contentSignature,
                        sendType: "短信发送", //短信
                        type: this.type,
                        unionid: this.userUnionId,
                      },
                      {
                        headers: {
                          "Content-Type": "application/json",
                        },
                      }
                    )
                    .then(() => {
                      this.handleTagClick({ value: this.type });
                    });
                }
              });
          })
          .catch(() => {});
        setTimeout(done, 1000);
        done(); //关闭
        //发送企业微信
      } else if (action === "cancel") {
        this.sendWechatMessage();
        this.$toast("内容已复制至聊天窗口!");
        done(); //关闭
      }
      done();
    },
    searchHis() {
      this.showHistory = true;
      this.$axios
        .post(
          `${this.$base}/admin/weChat/log`,
          {
            unionid: this.userUnionId,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (res.code === 200) {
            this.historyList = res.data;
          }
          console.log(res);
        });
    },
    //历史记录弹窗关闭事件
    hisClose(action, done) {
      if (action === "confirm") {
        done(); //关闭
        //发送企业微信
      } else if (action === "cancel") {
        done(); //关闭
      }
      done();
    },
    onClickRight() {
      this.$router.push({ path: "/batchSend", query: { type: this.type } });
    },
  },
  mounted() {
    this.onLoad();
    this.initWxTicket();
  },
};
</script>
<style scoped>
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}
</style>
